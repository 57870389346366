import React from "react";

import imgLB from "../../assets/image/logo-main-black.png";
import imgLW from "../../assets/image/logo-main-white.png";
import {OutboundLink} from 'gatsby-plugin-google-gtag';

const Footer = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <hr className="border-top border-default-color-2 m-0 p-0" />
          <div className="row align-items-center pt-3 pt-lg-22">
            <div className="col-md-6 text-center text-md-right">
              <div className="brand-logo mb-7 mb-lg-8">
                <div style={{display: 'inline-block'}}>
                  <img
                      className="mx-auto mx-0 light-version-logo default-logo"
                      src={imgLB}
                      alt=""
                  />
                  <img
                      className="dark-version-logo mx-auto mx-0"
                      src={imgLW}
                      alt=""
                  />
                </div>
              </div>
              <div className="copyright mb-5">
                <p className="mb-0 font-size-3 text-stone">
                  © 2020 כל הזכויות שמורות לפרומתאוס טכנולוגיה מציתה מהלכים בע״מ
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-left mt-auto">
              <div className="footer-right mb-5">
                <p className="mb-0 font-size-3 text-stone">
                  {" "}
                  <OutboundLink href="https://propertime.co.il/terms-of-service/" target='_blank' rel="noopener">תנאי שימוש</OutboundLink>
                  {" | "}
                  <OutboundLink href="https://propertime.co.il/privacy-policy/" target='_blank' rel="noopener">מדיניות פרטיות</OutboundLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
