// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-attendance-clock-js": () => import("./../../../src/pages/attendance-clock.js" /* webpackChunkName: "component---src-pages-attendance-clock-js" */),
  "component---src-pages-attendance-system-js": () => import("./../../../src/pages/attendance-system.js" /* webpackChunkName: "component---src-pages-attendance-system-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-propertime-efficient-time-reporting-js": () => import("./../../../src/pages/propertime-efficient-time-reporting.js" /* webpackChunkName: "component---src-pages-propertime-efficient-time-reporting-js" */),
  "component---src-pages-thank-you-for-sending-a-signup-request-js": () => import("./../../../src/pages/thank-you-for-sending-a-signup-request.js" /* webpackChunkName: "component---src-pages-thank-you-for-sending-a-signup-request-js" */)
}

