import React from "react";
import Footer1 from "./Footer1";
import Footer2 from "./Footer2";
import Footer3 from "./Footer3";

const Footer = ({ className, style, ...rest }) => {
  let FooterEl = Footer1;

  if (style === "style2") {
    FooterEl = Footer2;
  } else if (style === "style3") {
    FooterEl = Footer3;
  }

  return (
    <>
      {style !== "none" && <FooterEl className={className} {...rest} />}
    </>
  );
};

export default Footer;
