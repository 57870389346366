export const menuItems = [
  {
    name: "demos",
    label: "creative demos",
    items: [
      { name: "", label: "Home 01" },
      { name: "home-2", label: "Home 02" },
    ],
  },
  {
    name: "pages",
    label: "pages",
    items: [
      {
        name: "account",
        label: "Account",
        items: [
          { name: "signin", label: "Sign In" },
          { name: "signup", label: "Sign Up" },
        ],
      },
      { name: "about", label: "about" },
      { name: "contact", label: "contact" },
    ],
  },
];
