import React from "react";
import { Link } from "gatsby";

const Logo = ({ color = "front", height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      ProperTime
    </Link>
  );
};

export default Logo;
