import React from "react";
import imgLB from "../../assets/image/logo-main-black.png";

const LogoNoA = ({ color = "front", height, className = "", ...rest }) => {
  return (
    <div className={`${className}`} style={{color: '#22b0fc', display: 'inline-block'}} {...rest}>
        <img
            className="mx-auto mx-0 light-version-logo default-logo"
            src={imgLB}
            alt=""
        />
    </div>
  );
};

export default LogoNoA;
